import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { AbstractModal } from '@component/Modal';
import { Benefits } from '../../components/Benefits.vue';
let BuySubscriptionModal = class BuySubscriptionModal extends AbstractModal {
    onBegin() {
        this.$router.push({ name: 'subscription.choose.product' });
    }
};
BuySubscriptionModal = __decorate([
    Component({
        name: 'BuySubscriptionModal',
        components: { Benefits }
    })
], BuySubscriptionModal);
export { BuySubscriptionModal };
export default BuySubscriptionModal;
